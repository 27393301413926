import { GraphQLClient } from 'graphql-request'
import { enrichmentApiHost, graphqlApiUrl } from '@crystal-eyes/config'
import { AuthDetails } from '@crystal-eyes/types'
import { getOriginApp } from '@crystal-eyes/lib/origin_app'
export { gql } from 'graphql-request'
import { parse, DocumentNode } from 'graphql'
import {
  noticeError,
  trackExecution,
} from '@crystal-eyes/utils/instrumentation'
import getAuth from '@crystal-eyes/data/getAuth'

export const queryWithoutAuth = (
  query: string,
  variables?: { [key: string]: any },
  clientOpts: object = {},
): Promise<any> => {
  const doc: DocumentNode = parse(query)
  const defNames = doc.definitions
    .map((def: any) => def?.name?.value)
    .filter((defName: string) => defName)
    .join(',')

  const graphQLClient = new GraphQLClient(`${graphqlApiUrl!}?op=${defNames}`, {
    fetch: fetch,
    credentials: 'omit',
    ...clientOpts,
  })

  return graphQLClient
    .request(query, variables || {})
    .catch(function (graphqlError) {
      noticeError(`GraphQL Error: ${defNames}`, graphqlError)
      const errorMessages: string[] = []
      ;(graphqlError.response?.errors || []).forEach((currError: any) => {
        errorMessages.push(`${currError.path.join('.')}: ${currError.message}`)
      })

      const error = new Error()
      error.name = 'GraphQL (Unauthed)'
      error.message = errorMessages.join('\n')
      error.stack = graphqlError.stack
      return Promise.reject(error)
    })
}

export const queryWithAuth = (
  authData: AuthDetails | undefined,
  query: string,
  variables?: { [key: string]: any },
  clientOpts: object = {},
): Promise<any> => {
  if (!authData?.authToken) {
    return Promise.reject('queryWithAuth: No Auth Token')
  }

  const doc: DocumentNode = parse(query)
  const defNames = doc.definitions
    .map((def: any) => def?.name?.value)
    .filter((defName: string) => defName)
    .join(',')

  const graphQLClient = new GraphQLClient(`${graphqlApiUrl!}?op=${defNames}`, {
    fetch: fetch,
    credentials: 'omit',
    ...clientOpts,
  })

  const authHeaders = {
    authorization: `Bearer ${authData?.authToken}`,
    session: authData?.sessionToken || '',
    'Origin-App': getOriginApp(),
    ...(process.env.RUNTIME_ENV === 'server'
      ? { 'x-crystal-nextjs': '245e5f31-d71c-4403-bec2-8f05f0c46982' }
      : {}),
  }
  const request = trackExecution(`GraphQL: ${defNames}`, () => {
    return graphQLClient
      .request(query, variables || {}, authHeaders)
      .catch(function (graphqlError) {
        noticeError(`GraphQL Error: ${defNames}`, graphqlError)
        const errorMessages: string[] = []
        ;(graphqlError.response?.errors || []).forEach((currError: any) => {
          errorMessages.push(
            `${currError.path.join('.')}: ${currError.message}`,
          )
        })

        const error = new Error()
        error.name = 'GraphQL'
        error.message = errorMessages.join('\n')
        error.stack = graphqlError.stack
        return Promise.reject(error)
      })
  })

  return request
}

export const authedQuery = async (
  query: string,
  variables?: { [key: string]: any },
  clientOpts: object = {},
): Promise<any> => {
  const authData = await getAuth()
  return queryWithAuth(authData, query, variables, clientOpts)
}

export function fetchREST(path: string, options: RequestInit = {}) {
  const url = `${enrichmentApiHost}/${path}`

  return fetch(url, {
    ...options,
    credentials: 'omit',
    headers: {
      ...(options.headers || {}),
      ...(process.env.RUNTIME_ENV === 'server'
        ? { 'x-crystal-nextjs': '245e5f31-d71c-4403-bec2-8f05f0c46982' }
        : {}),
      'Content-Type': 'application/json',
    },
  })
}
